import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ErrorIcon from '@mui/icons-material/Error';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { amber, blue, green, grey, red } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import CircularProgressWithLabel from '~components/CircularProgressWithLabel';
import EmptyState from '~components/EmptyState';
import ControlledFileUpload from '~components/Form/ControlledFileUpload';
import OberonCard from '~components/OberonCard';
import { useNotification } from '~providers/NotificationProvider';
import Routes from '~providers/RouteProvider/Routes';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { getListImportById, getListImports, uploadLeadsListFileForList } from '../../api';
import { ListImport } from '../../domain';

interface Form {
  file: File | null;
}

const LeadListUpload = () => {
  const navigate = useNavigate();
  const { pushNotification } = useNotification();
  const { campaignId, listId } = useParams() as { campaignId: string; listId: string };
  const {
    formState: { errors, isValid },
    handleSubmit,
    control,
  } = useForm<Form>({
    defaultValues: {
      file: null,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const [listImports, setListImports] = useState<ListImport[] | undefined>();
  const [listImportsError, setListImportsError] = useState<{ text: string; subText: string } | undefined>();
  const [listImportsLoading, setListImportsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const loadingProgressCallback = (loadingProgress: number) => {
    setLoadingProgress((98 * loadingProgress) / 100);
  };

  const uploadFile = handleSubmit(async (data: Form) => {
    // File should not be null at this point so we cast to to File type
    const file = data.file as File;

    try {
      const res = await uploadLeadsListFileForList(+campaignId, +listId, file!, loadingProgressCallback);
      setLoadingProgress(100);
      navigate(
        generatePath(Routes.viewImportDetails.path, {
          campaignId,
          listId,
          importId: res.importId.toString(),
        }),
      );
    } catch (e) {
      pushNotification('error', 'Failed to import file');
      setLoadingProgress(0);
    }
  });
  const warningColor = amber[600];
  const errorColor = red[600];
  const listImportsDisplay = useMemo(
    () =>
      listImports && listImports.length > 0 ? (
        listImports.map((i) => {
          function ListImportCard() {
            const [listImport, setListImport] = useState(i);
            const processing = listImport.processTimestamp && !Boolean(listImport.applied ?? listImport.discarded);
            async function checkImportProcess() {
              while (true) {
                if (!processing) {
                  break;
                }

                // wait for 2seconds
                await new Promise((r) => setTimeout(r, 2000));
                const li = await getListImportById(+campaignId, +listId, listImport.importId);
                setListImport(li);
              }
            }
            useEffect(() => {
              (async () => {
                await checkImportProcess();
              })();
            }, []);

            let footerColor: string = blue['600'];
            if (listImport.processTimestamp) {
              footerColor = blue['500'];
            }
            if (listImport.applied) {
              footerColor = green['500'];
            }
            if (listImport.discarded) {
              footerColor = grey['500'];
            }
            return (
              <OberonCard
                to={generatePath(Routes.viewImportDetails.path, {
                  campaignId,
                  listId,
                  importId: listImport.importId.toString(),
                })}
                title={`${listImport.fileName} #${listImport.importId}`}
                subHeader={
                  <Box sx={{ marginTop: 1, color: 'textSecondary' }}>
                    <div>
                      <b>Uploaded By:</b> {listImport.createdBy}
                    </div>
                    <div>
                      <b>Created At:</b> {listImport.importTimestamp}
                    </div>
                  </Box>
                }
                footer={
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 1.4,
                    }}>
                    <Typography
                      marginRight={1}
                      display='flex'
                      justifyContent='flex-start'
                      alignItems='center'
                      variant='caption'
                      color='textSecondary'>
                      <ErrorIcon sx={{ marginRight: 0.5, color: warningColor }} fontSize='small' />
                      {listImport.importWarningCount} import warnings
                    </Typography>

                    <Typography
                      marginRight={1}
                      display='flex'
                      justifyContent='flex-start'
                      alignItems='center'
                      variant='caption'
                      color='textSecondary'>
                      <ErrorIcon sx={{ marginRight: 0.5, color: errorColor }} fontSize='small' />
                      {listImport.importErrorCount} import errors
                    </Typography>

                    <Typography
                      marginRight={1}
                      display='flex'
                      justifyContent='flex-start'
                      alignItems='center'
                      variant='caption'
                      color='textSecondary'>
                      <ErrorIcon sx={{ marginRight: 0.5, color: errorColor }} fontSize='small' />
                      {listImport.parseErrorCount} parsing errors
                    </Typography>
                  </Box>
                }
                footerBorderColor={footerColor}
                action={processing && <CircularProgress />}
              />
            );
          }
          return <ListImportCard key={i.importId} />;
        })
      ) : (
        <EmptyState
          type='no-items-2'
          text='No imports currently exist'
          subText='Create a import by selecting a file and click the import button'
        />
      ),
    [listImports],
  );

  useEffect(
    () => {
      (async () => {
        // Note: we dont care about loading view states here as this data isnt technically
        // necessary to complete the file upload. In the future this will be handled on an import page level and will have loading
        try {
          setListImportsLoading(true);
          setListImports((await getListImports(+campaignId, +listId)).sort((a, b) => b.importId - a.importId));
        } catch (e) {
          if (e instanceof APIError) {
            setListImportsError({ text: 'Unable to request data from backend', subText: e.message });
          }

          if (e instanceof UnsupportedStructureError) {
            setListImportsError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
          }
        } finally {
          setListImportsLoading(false);
        }
      })();
    },
    [
      // uploadResults
    ],
  );

  return (
    <>
      <form noValidate onSubmit={uploadFile}>
        <Grid container spacing={1} justifyContent='center' alignContent='center'>
          <Grid item xs={12} md={6}>
            <ControlledFileUpload
              control={control}
              rules={{ required: 'File is required.' }}
              name='file'
              label='File'
              disabled={Boolean(loadingProgress)}
              required={true}
              error={Boolean(errors.file)}
              helperText={errors.file?.message}
            />
          </Grid>

          <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={6}>
            <Button
              fullWidth
              type='submit'
              variant='contained'
              disableElevation
              startIcon={<CloudUploadIcon />}
              disabled={!isValid || Boolean(loadingProgress)}
              color='primary'>
              Import File
            </Button>
          </Grid>
        </Grid>
      </form>

      {Boolean(loadingProgress) && (
        <Grid style={{ marginTop: 16, textAlign: 'center' }} item xs={12}>
          <CircularProgressWithLabel value={loadingProgress} />
          <Typography variant='body1' component='p' color='textSecondary'>
            Uploading List
          </Typography>
        </Grid>
      )}

      <Box sx={{ marginTop: 2 }}>
        <AsyncLoader
          isLoading={listImports == undefined || (listImports && listImports.length == 0 && listImportsLoading)}
          error={
            listImportsError && (
              <EmptyState type='error' text={listImportsError.text} subText={listImportsError.subText} />
            )
          }>
          {listImportsDisplay}
        </AsyncLoader>
      </Box>
    </>
  );
};

export default LeadListUpload;
