import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { triggerDiallerGroupReload } from '~pages/CampaignManagement/api';
import DiallerGroupArchiveForm from '~pages/CampaignManagement/DiallerGroupDetails/DiallerGroupSettings/DiallerGroupArchiveForm';
import { CampaignType, DiallerGroup, DiallerType, UpdateDiallerGroup } from '~pages/CampaignManagement/domain';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useAuth } from '~providers/AuthProvider';
import { AccessScope } from '~providers/AuthProvider/domain';
import { useNotification } from '~providers/NotificationProvider';
import Routes from '~providers/RouteProvider/Routes';

import GeneralSettingsForm from './GeneralSettingsForm';
import PredictiveSettingsForm from './PredictiveSettingsForm';
import PreviewSettingsForm from './PreviewSettingsForm';

interface DiallerGroupSettingsProps {
  diallerGroup: DiallerGroup;
  update: (data: Partial<UpdateDiallerGroup>) => void;
  archive: () => void;
}

const enum EditType {
  General = 'general',
  PreviewSettings = 'preview-settings',
  PredictiveSettings = 'predictive-settings',
}

const DiallerGroupSettings = ({ diallerGroup, update, archive }: DiallerGroupSettingsProps) => {
  const { hasScope } = useAuth();
  const appConfig = useAppConfiguration();
  const { pushNotification } = useNotification();
  const navigate = useNavigate();
  const [edit, setEdit] = useState<EditType | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const toggleEdit = (value: EditType) => () => {
    setEdit((prev) => (prev === value ? undefined : value));
  };

  const onSubmit = (formName: string, toggleValue: EditType) => async (data: Partial<UpdateDiallerGroup>) => {
    setSubmitting(true);

    try {
      await update(data);
    } catch (e) {
      pushNotification('error', `Failed to update ${formName}.`);
      setSubmitting(false);
      return;
    }

    if (
      appConfig.extensions.predictive !== undefined &&
      (diallerGroup.campaignType === CampaignType.Predictive ||
        diallerGroup.diallerType === DiallerType.SIP ||
        diallerGroup.diallerType !== data.diallerType ||
        diallerGroup.campaignType !== data.campaignType)
    ) {
      try {
        await triggerDiallerGroupReload(appConfig.extensions.predictive.diallerURL);
      } catch (e) {
        setSubmitting(false);
        console.error('! Failed to trigger dialler group reload for predictive dialler.');
        // Do not need to return here as the update we were after occurred, but we just were not able to trigger
        // a predictive dialler reload
      }
    }

    setSubmitting(false);
    toggleEdit(toggleValue)();
    pushNotification('success', `You have successfully updated ${formName}.`);
  };

  const archiveGroup = async () => {
    try {
      await archive();
    } catch (e) {
      pushNotification('error', 'Failed to archive group.');
      return;
    }

    navigate(Routes.diallerConfig.path);
    pushNotification('success', `The group ${diallerGroup.name} has been archived.`);
  };

  return (
    <>
      <GeneralSettingsForm
        diallerGroup={diallerGroup}
        isEdit={edit === EditType.General}
        submitting={submitting}
        toggleEdit={
          hasScope(AccessScope.CanUpdateDiallerGroupGeneralSettings) ? toggleEdit(EditType.General) : undefined
        }
        update={onSubmit('general settings', EditType.General)}
      />

      {diallerGroup.previewSettings && (
        <PreviewSettingsForm
          previewSettings={diallerGroup.previewSettings}
          isEdit={edit === EditType.PreviewSettings}
          submitting={submitting}
          toggleEdit={
            hasScope(AccessScope.CanUpdateDiallerGroupPreviewSettings)
              ? toggleEdit(EditType.PreviewSettings)
              : undefined
          }
          update={onSubmit('preview settings', EditType.PreviewSettings)}
        />
      )}

      {diallerGroup.predictiveSettings && (
        <PredictiveSettingsForm
          predictiveSettings={diallerGroup.predictiveSettings}
          isConnectPredictive={
            diallerGroup.diallerType === DiallerType.Connect && diallerGroup.campaignType === CampaignType.Predictive
          }
          isEdit={edit === EditType.PredictiveSettings}
          submitting={submitting}
          toggleEdit={
            hasScope(AccessScope.CanUpdateDiallerGroupPredictiveSettings)
              ? toggleEdit(EditType.PredictiveSettings)
              : undefined
          }
          update={onSubmit('predictive settings', EditType.PredictiveSettings)}
        />
      )}

      <DiallerGroupArchiveForm archived={diallerGroup.archived} archiveGroup={archiveGroup} />
    </>
  );
};

export default DiallerGroupSettings;
