import 'amazon-connect-streams';
// Leaving this package in here for now as without it connect will crash and explode if we configure Routing Profiles
// to allow chat contacts by mistake. Also, if G8 has a slow migration of brands to new async we could possibly get
// some chat customers in this UI which would be hairy.
import 'amazon-connect-chatjs';

import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';

import AsyncLoader from '~/components/AsyncLoader';
import EmptyState from '~/components/EmptyState';
import { useAppConfiguration } from '~/providers/AppConfigurationProvider';
import { DiallerType } from '~pages/CampaignManagement/domain';
import { useLogRocket } from '~providers/LogRocketProvider';
import { parseBoolean } from '~utils/Functions';

import { clearInternalTransferTarget, postCallMetrics, postInternalTransferTarget, postPaymentCTR } from './api';
import {
  AgentAvailableStates,
  AgentStateType,
  ConnectAgent,
  ConnectVoiceConnection,
  ConnectVoiceContact,
  ContactAttribute,
  ContactAttributeType,
  ContactDirection,
  ContactStateType,
  SoftphoneStreamType,
  TimeSeriesStats,
} from './domain';
import {
  acceptContact,
  addThirdPartyConnection,
  createConnectAgentStatusModel,
  createVoiceConnectionModel,
  destroyAgentSession,
  destroyThirdPartyConnection,
  dialPhoneNumber,
  downloadSessionLogs,
  endContactSession,
  getActiveAgentConnection,
  getEndpointByARN,
  getTimeSeriesStats,
  muteAgent,
  sendDtmfKeyInput,
  setAgentOffline,
  setAgentOnline,
  setAgentState,
  setContactComplete,
  setContactOnHold,
  setContactResume,
  unmuteAgent,
} from './helpers';

interface ConnectContext {
  agent: ConnectAgent;
  currentVoiceContact: ConnectVoiceContact | undefined;
  agentStateList: string[];
  callStatistics: TimeSeriesStats | undefined;
  downloadSessionLogs: () => void;
}

interface ConnectProviderProps {
  children: ReactNode;
}

type Error = {
  type: 'error' | 'puzzle';
  text: string;
  subText: string;
  action?: () => void;
  actionText?: string;
};

export const ConnectContext = createContext<ConnectContext | undefined>(undefined);

export const useConnect = (): ConnectContext => {
  return useContext(ConnectContext) as ConnectContext;
};

// Reference to required connect elements
const connectContainerId = 'connect-container';
// Reference to the connect iframe used for all streams based interactions
const connectIframeContainerId = 'connect-iframe';

const agentDefault: ConnectAgent = {
  name: '',
  routingProfile: '',
  defaultOutboundQueue: '',
  defaultOutboundQueueARN: '',
  status: {
    name: AgentAvailableStates.Init,
    type: AgentStateType.Init,
    startTimestamp: new Date().toISOString(),
  },
  softphoneAutoAnswerEnabled: false,
  // Voice-only
  isMuted: false,
  initialized: false,
  changeStatus: async (stateName: string) => {
    return { name: AgentAvailableStates.Init, type: AgentStateType.Init };
  },
  mute: async () => {
    alert('Not Implemented');
  },
  unmute: async () => {
    alert('Not Implemented');
  },
  dial: async (phoneNumber: string) => {
    alert('Not Implemented');
  },
  setOnline: async () => {
    alert('Not Implemented');
  },
  setOffline: async () => {
    alert('Not Implemented');
  },
  initiateRoutingProfileChange: async (routingProfile: string) => {
    alert('Not Implemented');
  },
};

const ConnectProvider = ({ children }: ConnectProviderProps) => {
  const logRocket = useLogRocket();
  const appConfig = useAppConfiguration();
  // Global connect agent reference, used for agent actions we do not want exposed out of this provider
  const ccpAgent = useRef<connect.Agent | undefined>(undefined);
  // Our agent reference object
  const [agent, setAgent] = useState<ConnectAgent>(agentDefault);
  const [currentVoiceContact, setCurrentVoiceContact] = useState<ConnectVoiceContact | undefined>(undefined);
  const [callStatistics, setCallStatistics] = useState<TimeSeriesStats | undefined>(undefined);
  const [agentStateList, setAgentStateList] = useState<string[]>([]);
  const [connectCoreError, setConnectCoreError] = useState<Error | undefined>(undefined);
  // Used for storing rtc stat posting internal
  const rtcInterval = useRef<number | undefined>(undefined);
  // Used for storing the cold internal transfer queue endpoint
  const internalTransferEndpoint = useRef<connect.Endpoint | undefined>(undefined);
  const paymentGatewayEndpoint = useRef<connect.Endpoint | undefined>(undefined);
  // Stores the most recent agent audio status
  const agentToDiallerAudioStats = useRef<connect.AudioStats | undefined>(undefined);
  // Stores the most recent contact audio status
  const diallerToAgentAudioStats = useRef<connect.AudioStats | undefined>(undefined);
  // Lets us know if the connect iframe has already been added to the dom or not. This is used to tell us
  // when to rehydrate contacts from the agent connection vs let connect do everything.
  // TODO: remove in future when we move towards a separate tab for the dialler
  const iframeAlreadyExists = useRef<boolean>(false);
  const [initialRun, setInitialRun] = useState<boolean>(true);

  const onAgentUpdate = async (agent: connect.Agent): Promise<void> => {
    console.log(`+ Event onAgentUpdate: Agent update`);

    const config = agent.getConfiguration();
    const softphoneAutoAnswerEnabled = config.softphoneAutoAccept;
    const routingProfile = config.routingProfile.name;
    const defaultOutboundQueue = config.routingProfile.defaultOutboundQueue.name;
    const defaultOutboundQueueARN = config.routingProfile.defaultOutboundQueue.queueARN;
    const status = createConnectAgentStatusModel(agent.getStatus());
    const agentStatesArray = agent.getAgentStates().map((i) => i.name);

    let connectionMuted = false;
    const agentConnection = getActiveAgentConnection(agent);
    if (agentConnection) {
      connectionMuted = agentConnection.isMute();
    }

    setAgentStateList(agentStatesArray);
    setAgent((prev) => ({
      ...prev,
      routingProfile: routingProfile,
      pendingRoutingProfile: prev.pendingRoutingProfile !== routingProfile ? prev.pendingRoutingProfile : undefined,
      defaultOutboundQueue: defaultOutboundQueue,
      defaultOutboundQueueARN: defaultOutboundQueueARN,
      softphoneAutoAnswerEnabled: softphoneAutoAnswerEnabled,
      status: status,
      isMuted: prev.isMuted || connectionMuted,
      changeStatus: setAgentState(agent),
      mute: muteAgent(agent),
      unmute: unmuteAgent(agent),
      dial: dialPhoneNumber(agent),
      setOnline: setAgentOnline(agent),
      setOffline: setAgentOffline(agent),
      initiateRoutingProfileChange: initiateRoutingProfileChange,
    }));

    ccpAgent.current = agent;
  };

  const onRtcSessionFailed = (rtcSession: connect.RtcSession, reason: string) => {
    console.error(`! Event RTC Session: Failed for Contact ID: ${rtcSession.callId}`);
    console.error(`! Failed due to ${reason}`);
  };

  // Called when the call is established (handshaked and media stream should be flowing)
  const onRtcSessionConnected = (rtcSession: connect.RtcSession) => {
    const contactId = rtcSession.callId;
    console.log(`+ Event RTC Session: Connected for Contact ID: ${contactId}`);

    rtcInterval.current = window.setInterval(() => {
      console.log(`+ Event RTC Session: Call Metrics Send Request for Contact ID: ${contactId}`);
      updateAndPostAudioStatesFromRtcSession(rtcSession);
    }, 5_000);
  };

  // Called when hangup is initiated (implies the call was successfully established).
  const onRtcSessionCompleted = (rtcSession: connect.RtcSession) => {
    console.log(`+ Event RTC Session: Completed for Contact ID: ${rtcSession.callId}`);

    clearInterval(rtcInterval.current);
    setCallStatistics(undefined);
    agentToDiallerAudioStats.current = undefined;
    diallerToAgentAudioStats.current = undefined;
  };

  const updateAndPostAudioStatesFromRtcSession = async (rtcSession: connect.RtcSession) => {
    const contactId = rtcSession.callId;
    const prevAgentToDiallerAudioStats = agentToDiallerAudioStats.current;
    const prevDiallerToAgentAudioStats = diallerToAgentAudioStats.current;
    let newStats;

    try {
      newStats = await rtcSession.getStats();
    } catch (e) {
      console.error('! unable fetch call metrics: ', e);
      return;
    }

    // NOTE(RJ): Input == from agent to connect. Output == from connect to agent
    // https://github.com/aws/connect-rtc-js/blob/6a3c5cf0a17939e0af31fde833cf1a355bcab401/src/js/rtc_session.js#L930
    // I disagree with Amazon here, as all stats are collected by the client, they should be using
    // client (agent) oriented naming. Connect may see things differently to these stats!
    agentToDiallerAudioStats.current = newStats.audio.input[0];
    diallerToAgentAudioStats.current = newStats.audio.output[0];

    if (agentToDiallerAudioStats.current !== undefined) {
      const stats = getTimeSeriesStats(
        agentToDiallerAudioStats.current,
        prevAgentToDiallerAudioStats,
        SoftphoneStreamType.AudioAgentToDialler,
      );

      // Get current stats on agents call conectivity
      setCallStatistics(stats);

      try {
        await postCallMetrics(contactId, stats, DiallerType.Connect);
      } catch (e) {
        console.error('! Unable to post agent to dialler audio stats due to error: ', e);
        // Return not required here as we want to fall through to the next post request if this one fails
      }
    } else {
      console.error('! Unable to post agent to dialler audio stats as they do not exist');
    }

    if (diallerToAgentAudioStats.current !== undefined) {
      const stats = getTimeSeriesStats(
        diallerToAgentAudioStats.current,
        prevDiallerToAgentAudioStats,
        SoftphoneStreamType.AudioDiallerToAgent,
      );

      try {
        await postCallMetrics(contactId, stats, DiallerType.Connect);
      } catch (e) {
        console.error('! Unable to post dialler to agent audio stats due to error: ', e);
        // Return not required here as we want to fall through if this one fails
      }
    } else {
      console.error('! Unable to post dialler to agent audio stats as they do not exist');
    }
  };

  const initializeSoftphoneSession = ({ connectionId }: { connectionId: string }) => {
    const softphoneManager = connect.core.getSoftphoneManager();

    if (!softphoneManager) {
      return;
    }

    const session = softphoneManager.getSession(connectionId);
    session.onSessionFailed = onRtcSessionFailed;
    session.onSessionConnected = onRtcSessionConnected;
    session.onSessionCompleted = onRtcSessionCompleted;
  };

  const onMuteToggle = (obj: connect.AgentMutedStatus): void => {
    console.log(`+ Event onMuteToggle: Agent update`);

    setAgent((prev) => ({
      ...prev,
      isMuted: obj.muted,
    }));
  };

  const handleAccessDenied = (): void => {
    setConnectCoreError({
      type: 'error',
      text: 'User Configuration Error',
      subText: 'You have been authenticated successfully but do not have authorization to use this application.',
    });
  };

  const initializeConnectContact = (contact: connect.Contact): void => {
    console.log(`+ Initialize Contact ${contact.getContactId()}`);

    if (contact.getType() !== connect.ContactType.VOICE) {
      console.warn('Potential connect misconfiguration, got contact with type: ', contact.getType());
      return;
    }

    contact.onRefresh(onContactUpdate('onRefresh'));
    contact.onIncoming(onContactUpdate('onIncoming'));
    contact.onPending(onContactUpdate('onPending'));
    contact.onConnecting(onContactUpdate('onConnecting'));
    contact.onAccepted(onContactUpdate('onAccepted'));
    contact.onConnected(onContactConnected);
    contact.onMissed(onContactMissed);
    contact.onACW(onContactUpdate('onACW'));
    contact.onDestroy(onContactDestroy('onDestroy'));
    contact.onError(onContactDestroy('onError'));

    setCurrentVoiceContact(createVoiceContactModel(contact));
  };

  const initiateRoutingProfileChange = (routingProfile: string) => {
    setAgent((prev) => ({ ...prev, pendingRoutingProfile: routingProfile }));
  };

  const initializeAgent = async (agent: connect.Agent): Promise<void> => {
    console.log(`+ Initialize Agent ${agent.getName()}`);

    const name = agent.getName();
    const config = agent.getConfiguration();
    const softphoneAutoAnswerEnabled = config.softphoneAutoAccept;
    const routingProfile = config.routingProfile.name;
    const defaultOutboundQueue = config.routingProfile.defaultOutboundQueue.name;
    const defaultOutboundQueueARN = config.routingProfile.defaultOutboundQueue.queueARN;
    const status = createConnectAgentStatusModel(agent.getStatus());
    const agentStatesArray = agent.getAgentStates().map((i) => i.name);
    let connectionMuted = false;
    const agentConnection = getActiveAgentConnection(agent);
    if (agentConnection) {
      connectionMuted = agentConnection.isMute();
    }

    // TODO: remove when we are 100% sure that the double event subscription does not occur due to this not being wrapped
    if (iframeAlreadyExists.current === true) {
      // This handles the case where you navigate away mid call and come back
      // when you have connected contact (voice/ chat). The connect.contact,
      // will not rerun contact initializations, leading to a delay in view
      // being update with contact as we are waiting for the contact.onRefresh event to fire
      agent.getContacts().map((contact) => {
        try {
          initializeConnectContact(contact);
        } catch (e) {
          // AWS connect functions swallow JavaScript errors,
          // so I catch and throw errors here.
          console.error('Unable to reinitialise contact due to error: ', e);
          throw e;
        }
      });
    }

    ccpAgent.current = agent;

    if (appConfig.extensions.internalTransfer) {
      const internalTransferARN = appConfig.extensions.internalTransfer.quickConnectARN;
      let endpoint: connect.Endpoint | undefined;

      try {
        endpoint = await getEndpointByARN(agent)(internalTransferARN);
      } catch (e) {
        // do nothing with given error, we handle below
      }

      if (endpoint === undefined) {
        console.error(
          `+ unable to find quick connect endpoint with ARN: `,
          internalTransferARN,
          ' is your Queue configured with this Quick Connect item?',
        );
      } else {
        if (internalTransferEndpoint.current !== undefined) {
          throw new Error(
            'Unexpected error. Expected internalTransferEndpoint to be undefined, was it cleaned up in a useEffect function?',
          );
        }
        internalTransferEndpoint.current = endpoint;
      }
    }

    if (appConfig.extensions.paymentGateway) {
      const paymentGatewayARN = appConfig.extensions.paymentGateway.quickConnectARN;
      let paymentEndpoint: connect.Endpoint | undefined;

      try {
        paymentEndpoint = await getEndpointByARN(agent)(paymentGatewayARN);
      } catch (e) {
        // do nothing with given error, we handle below
      }

      if (paymentEndpoint === undefined) {
        console.error(`! Failed to find payment gateway endpoint: `, paymentGatewayARN);
      } else {
        if (paymentGatewayEndpoint.current !== undefined) {
          throw new Error(
            'Unexpected error. Expected paymentGatewayEndpoint to be undefined, was it cleaned up in a useEffect function?',
          );
        }

        paymentGatewayEndpoint.current = paymentEndpoint;
      }
    }

    // As agent.onStateChange() does not do its job we use agent.onRefresh() instead.
    agent.onRefresh(onAgentUpdate);
    agent.onMuteToggle(onMuteToggle);
    agent.onWebSocketConnectionGained((agent) => {
      console.log('++++ connection gained');
      initializeAgent(agent);
    });
    agent.onWebSocketConnectionLost((agent) => {
      console.error('!!!! connection lost, re-initializing agent');
      initializeAgent(agent);
    });
    agent.onSoftphoneError((error) => {
      console.error('!!!! Agent Softphone error', error);
    });
    agent.onError((agent) => {
      // Note: status name will be the error, status type will always be error
      console.error('!!!! Agent Error', agent.getStatus().name, agent.getStatus().type);
    });

    setAgentStateList(agentStatesArray);
    setAgent({
      name: name,
      routingProfile: routingProfile,
      pendingRoutingProfile: undefined,
      defaultOutboundQueue: defaultOutboundQueue,
      defaultOutboundQueueARN: defaultOutboundQueueARN,
      softphoneAutoAnswerEnabled: softphoneAutoAnswerEnabled,
      status: status,
      isMuted: connectionMuted,
      initialized: true,
      changeStatus: setAgentState(agent),
      mute: muteAgent(agent),
      unmute: unmuteAgent(agent),
      dial: dialPhoneNumber(agent),
      setOnline: setAgentOnline(agent),
      setOffline: setAgentOffline(agent),
      initiateRoutingProfileChange: initiateRoutingProfileChange,
    });
  };

  const sendContactToVoicemailMessage =
    (contact: connect.Contact) =>
    async (quickConnectARN: string): Promise<void> => {
      if (ccpAgent.current === undefined) {
        throw new Error('sendContactToVoicemailMessage: Unexpected error. Agent is undefined');
      }

      try {
        const endpoint = await getEndpointByARN(ccpAgent.current)(quickConnectARN);
        await addThirdPartyConnection(contact)(endpoint);
        await endContactSession(contact)();
      } catch (e) {
        if (typeof e === 'string') {
          throw new Error(e);
        }

        throw e;
      }
    };

  const internalTransfer =
    (contact: connect.Contact) =>
    async (transferTargetAgent: string): Promise<void> => {
      if (internalTransferEndpoint.current === undefined) {
        throw new Error('Unexpected error. internalTransferEndpoint is undefined');
      }

      // Initial ID will only be set if the contact has been an internal connect transfer.
      // So we use the current id til it is set.
      const initialContactId = contact.getInitialContactId() || contact.getContactId();

      try {
        await postInternalTransferTarget(initialContactId, transferTargetAgent);
        await addThirdPartyConnection(contact)(internalTransferEndpoint.current);
      } catch (e) {
        if (typeof e === 'string') {
          throw new Error(e);
        } else {
          throw new Error(
            'Unexpected error. Unable to initiate connection with third party contact (transfer target agent).',
          );
        }
      }

      logRocket.trackEvent('transfer');
      logRocket.trackEvent('internal_transfer');
    };

  const cancelInternalTransfer =
    (contact: connect.Contact) =>
    async (onlyClearTransferTarget?: boolean): Promise<void> => {
      const initialContactId = contact.getInitialContactId();

      try {
        await clearInternalTransferTarget(initialContactId);
        // We only want this to run if this property is false
        if (Boolean(onlyClearTransferTarget) === false) {
          await destroyThirdPartyConnection(contact)();
        }
      } catch (e) {
        if (typeof e === 'string') {
          throw e;
        } else {
          throw 'Unexpected error. Unable to transfer.';
        }
      }
    };

  const conferenceTransfer =
    (contact: connect.Contact) =>
    async (quickConnectARN: string): Promise<void> => {
      if (ccpAgent.current === undefined) {
        throw new Error('conferenceTransfer: Unexpected error. Agent is undefined');
      }

      try {
        const endpoint = await getEndpointByARN(ccpAgent.current)(quickConnectARN);
        await addThirdPartyConnection(contact)(endpoint);
      } catch (e) {
        if (typeof e === 'string') {
          throw new Error(e);
        } else {
          throw new Error(
            'Unexpected error. Unable to initiate connection with third party contact (transfer target agent).',
          );
        }
      }
    };

  const externalTransfer =
    (contact: connect.Contact) =>
    async (phoneNumber: string): Promise<void> => {
      if (ccpAgent.current === undefined) {
        throw new Error('conferenceTransfer: Unexpected error. Agent is undefined');
      }

      try {
        let endpoint: string | connect.Endpoint = phoneNumber;
        await addThirdPartyConnection(contact)(endpoint);
      } catch (e) {
        if (typeof e === 'string') {
          throw new Error(e);
        } else {
          throw new Error(
            'Unexpected error. Unable to initiate connection with third party contact (transfer target agent).',
          );
        }
      }
    };

  const conferencePaymentGateway =
    (contact: connect.Contact) =>
    async (paymentId: string): Promise<void> => {
      if (paymentGatewayEndpoint.current === undefined) {
        throw new Error('conferencePaymentGateway: Unexpected error. paymentGatewayEndpoint is undefined');
      }

      const contactId = contact.getContactId();

      try {
        await postPaymentCTR(contactId, paymentId);
        await addThirdPartyConnection(contact)(paymentGatewayEndpoint.current);
      } catch (e) {
        if (typeof e === 'string') {
          throw e;
        } else {
          throw 'Unexpected error. Unable to transfer.';
        }
      }

      logRocket.trackEvent('transfer');
      logRocket.trackEvent('payment_gateway');
    };

  const onContactUpdate =
    (eventType: string) =>
    (contact: connect.Contact): void => {
      console.log(`+ Event ${eventType}: Contact update`);

      if (contact.getType() !== connect.ContactType.VOICE) {
        console.warn('Potential connect misconfiguration, got contact with type: ', contact.getType());
        return;
      }

      setCurrentVoiceContact(createVoiceContactModel(contact));
    };

  const onContactConnected = async (contact: connect.Contact): Promise<void> => {
    console.log(`+ Event onConnected: Contact update`);

    if (contact.getType() !== connect.ContactType.VOICE) {
      console.warn('Potential connect misconfiguration, got contact with type: ', contact.getType());
      return;
    }

    setCurrentVoiceContact(createVoiceContactModel(contact));

    logRocket.trackKeyValue('aws_connect_queue_name', contact.getQueue().name);
    logRocket.trackKeyValue('aws_connect_queue_arn', contact.getQueue().queueARN);
  };

  const onContactMissed = (contact: connect.Contact): void => {
    console.log(`+ Event onMissed: Contact missed`);

    // Handle our logic in an async event so that errors aren't swallowed by the event handler
    // It also stops errors like the following from appearing in console:
    // - 'contact::destroyed::CONTACT_ID_HERE' event handler failed.
    (async () => {
      contact.clear({
        success: () => {
          console.log('+ Cleared missed contact');
        },
        failure: () => {
          console.error('! Unable to clear missed contact.');
        },
      });
    })();
  };

  const onContactDestroy =
    (eventType: string) =>
    (contact: connect.Contact): void => {
      console.log(`+ Event ${eventType}: Contact destroy`);

      if (contact.getType() !== connect.ContactType.VOICE) {
        console.warn('Potential connect misconfiguration, got contact with type: ', contact.getType());
        return;
      }

      setCurrentVoiceContact(undefined);
    };

  const createVoiceContactModel = (contact: connect.Contact): ConnectVoiceContact => {
    const contactId = contact.getContactId();
    const {
      customer_name,
      campaign_id,
      attempt_id,
      lead_id,
      brand,
      transfer_target_agent,
      realtime_analytics,
      agent_paused_recording,
      predictive_queue,
      ...others
    } = contact.getAttributes();
    const status = contact.getStatus();
    const statusType = status.type as unknown as ContactStateType;
    const statusTimestamp = status.timestamp;
    const isMultiPartyCallEnabled = contact.isMultiPartyConferenceEnabled();
    const initialConnection = contact.getInitialConnection();
    const phoneNumber = initialConnection?.getEndpoint()?.phoneNumber;
    const attemptId = +attempt_id?.value || undefined;
    const leadId = +lead_id?.value || undefined;
    const hasRealtimeAnalytics = realtime_analytics?.value ? parseBoolean(realtime_analytics.value) : false;
    const isRecordingPaused = agent_paused_recording?.value ? parseBoolean(agent_paused_recording.value) : false;
    const queueInfo = contact.getQueue();
    const queueTimestamp = contact.getQueueTimestamp();
    const campaignId = campaign_id !== undefined ? +campaign_id.value : undefined;
    const transferTargetAgent = transfer_target_agent?.value || undefined;
    const isPredictive = predictive_queue !== undefined;
    let attributes: ContactAttribute[] = [];

    // Retrieve list of display attributes
    for (const key of Object.keys(others)) {
      if (key.startsWith('cmi')) {
        const split = key.split('_');
        const aType = split[1];

        if (
          aType !== ContactAttributeType.Link &&
          aType !== ContactAttributeType.Img &&
          aType !== ContactAttributeType.Text
        ) {
          console.warn(`Unknown attribute type of ${aType}. Attribute skipped.`);
          continue;
        }

        const aValue = others[key].value;
        const aKey = split.slice(2).join(' ');

        const attr = {
          id: key,
          type: aType as ContactAttributeType,
          label: aKey,
          value: aValue,
        };

        // We want images to appear first, then move on to other property types
        attributes = aType === ContactAttributeType.Img ? [attr, ...attributes] : [...attributes, attr];
      }
    }

    let connections: ConnectVoiceConnection[] = [];
    const agentConnection = contact.getAgentConnection();
    if (contact.getType() === connect.ContactType.VOICE) {
      if (agentConnection && agentConnection.isActive()) {
        connections = contact
          .getConnections()
          // include active connections only, inactive connections can be removed when add new connection to contact
          .filter((c) => c.isActive())
          // there is only one agent connection which is myself, connection type of other agents are not agent
          .filter((c) => c.getType() !== connect.ConnectionType.AGENT)
          .map((c) => createVoiceConnectionModel(contact, c as connect.VoiceConnection));
      }
    }

    let newContact: ConnectVoiceContact = {
      campaignId: campaignId,
      contactId: contactId,
      initialContactId: contact.getOriginalContactId() ?? contactId,
      attemptId: attemptId,
      leadId: leadId,
      phoneNumber: phoneNumber,
      name: customer_name?.value ?? null,
      statusType: statusType,
      statusTimestamp: statusTimestamp,
      direction: contact.isInbound() ? ContactDirection.Inbound : ContactDirection.Outbound,
      isPredictive,
      // For some reason connect started leaving this value true for contact's within the ended
      // status for the current agent. (transfer and on hold scenarios)
      isOnHold: statusType === ContactStateType.Connected && agentConnection.isOnHold(),
      hasActiveContactConnection: Boolean(contact.getActiveInitialConnection()),
      hasActiveThirdPartyConnection: Boolean(contact.getSingleActiveThirdPartyConnection()),
      brand: brand?.value ?? null,
      hasRealtimeAnalytics: hasRealtimeAnalytics,
      attributes: attributes,
      transferTargetAgent: transferTargetAgent,
      isMultiPartyCallEnabled: isMultiPartyCallEnabled,
      isRecordingPaused: isRecordingPaused,
      queueARN: queueInfo.queueARN,
      queue: queueInfo.name,
      queueTimestamp: queueTimestamp,
      connections: connections,
      initiateExternalTransfer: externalTransfer(contact),
      initiateInternalTransfer: internalTransfer(contact),
      cancelInternalTransfer: cancelInternalTransfer(contact),
      conferenceTransfer: conferenceTransfer(contact),
      initiatePaymentConference: conferencePaymentGateway(contact),
      endSession: endContactSession(contact),
      accept: acceptContact(contact),
      putOnHold: setContactOnHold(contact),
      takeOffHold: setContactResume(contact),
      complete: setContactComplete(contact),
      sendDTMF: sendDtmfKeyInput(contact),
      sendToVoicemailMessage: sendContactToVoicemailMessage(contact),
    };

    return newContact;
  };

  // Connect connection setup
  useEffect(() => {
    // Gross but it fixes the issue where connect fails to reconnect on dev StrictMode second pass due to them not being
    // smart with generating and reconnecting existing iframes
    if (initialRun) {
      setInitialRun(false);
      return;
    }

    /**
     * Inject connect container related elements into the DOM if they do not exist
     *
     * The reason for doing it this way is to prevent react from cleaning it up before
     * we are really done with it. For reference if we are using the jsx block with a ref,
     * the dom on unmount is cleaned up before the useEffects cleanup function
     * leading to the iframe being removed before we have performed specific streams related cleanup.
     * i.e. setting agent to offline and termination the connect session
     *
     * We also check if its defined as an admin user might be in a call and if they navigate
     * away from this page, we might not be able to destroy the session due to a contact bei.ng connected
     * and we want to avoid duplicate injections of the same div
     */
    if (document.getElementById(connectContainerId) === null) {
      iframeAlreadyExists.current = false;

      const element = (
        <div id={connectContainerId}>
          <audio id='remote-audio' autoPlay></audio>
          <div
            id={connectIframeContainerId}
            style={{
              position: 'fixed',
              left: 16,
              bottom: 16,
              display: 'none',
              width: 0,
              height: 0,
              zIndex: 1201,
            }}
          />
        </div>
      );

      document.body.insertAdjacentHTML('beforeend', ReactDOMServer.renderToStaticMarkup(element));
    } else {
      iframeAlreadyExists.current = true;
    }

    connect.core.initCCP(document.getElementById(connectIframeContainerId)!, {
      ccpUrl: appConfig.aws.amazonConnectCcpUrl,
      region: appConfig.aws.amazonConnectRegion,
      loginPopup: true,
      loginUrl: appConfig.aws.amazonConnectLoginUrl,
      loginOptions: {
        autoClose: true,
        left: 100,
        top: 100,
        width: 150,
        height: 150,
      },
      softphone: {
        allowFramedSoftphone: false,
        disableRingtone: false,
      },
      // https://docs.aws.amazon.com/connect/latest/adminguide/admin-3pcookies.html
      // https://docs.aws.amazon.com/connect/latest/adminguide/3pcookies.html
      // https://github.com/amazon-connect/amazon-connect-streams/issues/782
      // This seems to be a bit buggy atm, the above issue makes mention of logging in an agent to connect first before
      // prompting them to click the banner. How the heck does this work with SAML???
      storageAccess: {
        canRequest: true,
      },
    });

    connect.storageAccess.onRequest({
      onInit: (event) => {
        if (!event.data.hasAccess) {
          setConnectCoreError({
            type: 'puzzle',
            text: '3rd party cookies are blocked',
            subText:
              '3rd party cookies must be enabled in order to continue. Enable 3rd party cookies and refresh the page to continue.',
            action: () => window.location.reload(),
            actionText: 'Refresh',
          });
        }
      },
      onGrant: () => {
        // after the agent allows the permission, you may want to close the banner
        setConnectCoreError(undefined);
      },
    });

    // Connect Core Error handling
    connect.core.onAccessDenied(handleAccessDenied);

    /**
     * NOTE(christian): 2021-01-27
     *
     * Metric Reporting
     *
     * Since the rtc library does not expose types at the moment we have extended them in our own declaration.d.ts file in the interim
     * to pass type checking build logic
     *
     * Softphone related metric logic uses undocumented functionality. This logic uses the following link as a guide
     * @see {@link https://github.com/amazon-connect/amazon-connect-call-quality-monitoring/blob/f8e175da7808b9bd9a161801191850cf1d5f69e1/monitoring-stack-cdk/resources/frontend/connect-custom-implementation.js#L298}
     *
     */
    connect.core.initSoftphoneManager({ allowFramedSoftphone: true });
    connect.core.onSoftphoneSessionInit(initializeSoftphoneSession);

    connect.agent(async (agent) => {
      try {
        await initializeAgent(agent);
      } catch (e) {
        // AWS connect functions swallow JavaScript errors,
        // so I catch and throw errors here.
        console.error(e);
        throw e;
      }
    });

    connect.contact((contact) => {
      try {
        initializeConnectContact(contact);
      } catch (e) {
        // AWS connect functions swallow JavaScript errors,
        // so I catch and throw errors here.
        console.error(e);
        throw e;
      }
    });

    return () => {
      (async () => {
        console.log('+ Connect Provider Cleanup (hook cleanup)');
        // TODO: should we maybe remove this from here as if a call is connected we shouldnt kill it,
        //       let existing events kill it???
        // Cleanup rtc metric posting if navigation occurs when active
        clearInterval(rtcInterval.current);

        if (ccpAgent.current === undefined) {
          // Remove the connect container from the DOM if it exists
          const container = document.getElementById(connectContainerId);
          if (container !== null) {
            document.body.removeChild(container);
          }

          return;
        }

        // This will destroy the agents session and set them offline IF they are not currently
        // connected to a contact i.e. contact connected states
        await destroyAgentSession(ccpAgent.current);

        // Remove the connect container from the DOM if it exists
        const container = document.getElementById(connectContainerId);
        if (container !== null) {
          document.body.removeChild(container);
        }
      })();
    };
  }, [initialRun]);

  const context: ConnectContext = {
    agent,
    currentVoiceContact,
    agentStateList,
    callStatistics,
    downloadSessionLogs,
  };

  return (
    <>
      {connectCoreError && (
        <EmptyState
          type={connectCoreError.type}
          text={connectCoreError.text}
          subText={connectCoreError.subText}
          action={connectCoreError.action}
          actionText={connectCoreError.actionText}
        />
      )}

      {!connectCoreError && (
        <AsyncLoader isLoading={!agent.initialized}>
          <ConnectContext.Provider value={context}>{children}</ConnectContext.Provider>
        </AsyncLoader>
      )}
    </>
  );
};

export default ConnectProvider;
