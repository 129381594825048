import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';
import Chip from '@mui/material/Chip';
import { amber, green, grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React, { ForwardedRef, forwardRef } from 'react';
import { generatePath } from 'react-router-dom';

import OberonCard from '~components/OberonCard';
import ToggleAction from '~components/ToggleAction';
import { triggerDiallerGroupReload } from '~pages/CampaignManagement/api';
import { CampaignType, DiallerGroup, typeIdsToDisplayText } from '~pages/CampaignManagement/domain';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useAuth } from '~providers/AuthProvider';
import { AccessScope } from '~providers/AuthProvider/domain';
import Routes from '~providers/RouteProvider/Routes';
import { pluraliseWord } from '~utils/Functions';

import useDiallerGroup from '../../DiallerGroupDetails/useDiallerGroup';

type Props = {
  group: DiallerGroup;
};

const GroupCard = forwardRef(({ group: item }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const { hasScope } = useAuth();
  const appConfig = useAppConfiguration();
  const { data, update } = useDiallerGroup(item.id, false);
  const isActive = data?.isActive ?? item.isActive;
  const isArchived = Boolean(item.archived);
  const backgroundAndBorderColor = isArchived ? grey[600] : isActive ? green[600] : amber[600];
  const statusName = isArchived ? 'Archived' : isActive ? 'Active' : 'Paused';

  return (
    <OberonCard
      ref={ref}
      title={item.name}
      subHeader={
        <Chip
          sx={{
            marginTop: 2,
            textTransform: 'uppercase',
            fontSize: 10,
            borderRadius: 1,
            height: 20,
            lineHeight: '21px',
            color: '#ffffff',
            fontWeight: 700,
            backgroundColor: backgroundAndBorderColor,
          }}
          label={statusName}
        />
      }
      titleFontWeight={400}
      to={generatePath(Routes.viewDiallerGroup.path, { diallerGroupId: item.id.toString() })}
      action={
        hasScope(AccessScope.CanUpdateDiallerGroupGeneralSettings) && !isArchived ? (
          <ToggleAction
            checked={isActive}
            action={async (v) => {
              await update({ isActive: v });
              if (appConfig.extensions.predictive !== undefined && item.campaignType === CampaignType.Predictive) {
                try {
                  await triggerDiallerGroupReload(appConfig.extensions.predictive.diallerURL);
                } catch (e) {
                  console.error('! Failed to trigger dialler group reload for predictive dialler.');
                }
              }
            }}
          />
        ) : undefined
      }
      footer={
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            {item.numAgents !== undefined && (
              <Typography
                marginRight={1}
                display='flex'
                justifyContent='flex-start'
                alignItems='center'
                variant='caption'
                color='textSecondary'>
                <HeadsetMicIcon sx={{ marginRight: 0.5 }} fontSize='small' />
                {item.numAgents.toLocaleString()} {pluraliseWord(item.numAgents, 'agent')}
              </Typography>
            )}

            {item.numCampaigns !== undefined && (
              <Typography
                marginRight={1}
                display='flex'
                justifyContent='flex-start'
                alignItems='center'
                variant='caption'
                color='textSecondary'>
                <SettingsPhoneIcon sx={{ marginRight: 0.5 }} fontSize='small' />
                {item.numCampaigns.toLocaleString()} {pluraliseWord(item.numCampaigns, 'campaign')}
              </Typography>
            )}
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              display='flex'
              justifyContent='flex-start'
              alignItems='center'
              variant='caption'
              color='textSecondary'>
              {typeIdsToDisplayText[item.diallerType]} | {typeIdsToDisplayText[item.campaignType]}
            </Typography>
          </Box>
        </Box>
      }
      footerBorderColor={backgroundAndBorderColor}
    />
  );
});

export default GroupCard;
