import * as j from '@mojotech/json-type-validation';

export interface LeadListImportData {
  importId: number;
  importRecordNumber: number;
  importError: string | null;
  importWarning: string | null;
  originalData: string | null;
  parseError: string | null;
  parsed: string | null;
}

export interface LeadListImportResponse {
  nextPageUrl: string | null;
  importData: LeadListImportData[];
}

export const GetLeadListImportDataDecoder: j.Decoder<LeadListImportData> = j
  .object({
    import_id: j.number(),
    import_record_number: j.number(),
    import_error: j.union(j.string(), j.constant(null)),
    import_warning: j.union(j.string(), j.constant(null)),
    original_data: j.union(j.string(), j.constant(null)),
    parse_error: j.union(j.string(), j.constant(null)),
    parsed: j.union(j.string(), j.constant(null)),
  })
  .map((item: any) => ({
    importId: item.import_id,
    importRecordNumber: item.import_record_number,
    importError: item.import_error,
    importWarning: item.import_warning,
    originalData: item.original_data,
    parseError: item.parse_error,
    parsed: item.parsed,
  }));

export const GetLeadListImportResponseDecoder: j.Decoder<LeadListImportResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    import_data: j.array(GetLeadListImportDataDecoder),
  })
  .map((item) => ({
    nextPageUrl: item.next_page_url,
    importData: item.import_data,
  }));
