import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import ContactlessTwoToneIcon from '@mui/icons-material/ContactlessTwoTone';
import HeadsetMicTwoToneIcon from '@mui/icons-material/HeadsetMicTwoTone';
import ImportContactsTwoToneIcon from '@mui/icons-material/ImportContactsTwoTone';
import PhoneCallbackTwoToneIcon from '@mui/icons-material/PhoneCallbackTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';

import AsyncManagement from '~pages/AsyncManagement';
import QueueDetails from '~pages/AsyncManagement/QueueDetails';
import Callbacks from '~pages/Callbacks';
import CallStatistics from '~pages/CallStatistics';
import CampaignManagement from '~pages/CampaignManagement';
import CampaignDetails from '~pages/CampaignManagement/CampaignDetails';
import DiallerGroupDetails from '~pages/CampaignManagement/DiallerGroupDetails';
import LeadListDetails from '~pages/CampaignManagement/LeadListDetails';
import ImportDetails from '~pages/CampaignManagement/LeadListDetails/LeadListUpload/ImportDetails';
import ContactLog from '~pages/ContactLog';
import ContactDetails from '~pages/ContactLog/ContactDetails';
import Dialler from '~pages/Dialler';
import NotFound from '~pages/NotFound';
import SystemManagement from '~pages/SystemManagement';
import AccessFilterDetails from '~pages/SystemManagement/AccessFilterDetails';
import AgentDetails from '~pages/SystemManagement/AgentDetails';
import { PolicyType } from '~providers/AuthProvider/domain';

import Page from './Page';

export default class Routes {
  static dialler = new Page(
    '/dialler',
    [PolicyType.Agent, PolicyType.TeamLeader, PolicyType.DiallerAdmin],
    'Dialler',
    HeadsetMicTwoToneIcon,
    Dialler,
  );

  static contactLogs = new Page(
    '/contact-logs',
    [PolicyType.TeamLeader, PolicyType.Manager, PolicyType.QualityAnalyst, PolicyType.DiallerAdmin],
    'Contact Logs',
    ImportContactsTwoToneIcon,
    ContactLog,
  );

  static contactLogDetails = new Page(
    '/contact-logs/details/:contactId',
    [PolicyType.TeamLeader, PolicyType.Manager, PolicyType.QualityAnalyst, PolicyType.DiallerAdmin],
    'Contact Details',
    null,
    ContactDetails,
  );

  static callStatistics = new Page(
    '/call-statistics',
    [PolicyType.DiallerAdmin],
    'Call Statistics',
    AssessmentTwoToneIcon,
    CallStatistics,
  );

  static diallerConfig = new Page(
    '/dialler-config',
    [
      PolicyType.TeamLeader,
      PolicyType.Manager,
      PolicyType.RealTimeAnalyst,
      PolicyType.WorkforceAnalyst,
      PolicyType.DiallerAdmin,
    ],
    'Dialler Configuration',
    BallotTwoToneIcon,
    CampaignManagement,
  );

  static viewDiallerGroup = new Page(
    '/group/:diallerGroupId',
    [
      PolicyType.TeamLeader,
      PolicyType.Manager,
      PolicyType.RealTimeAnalyst,
      PolicyType.WorkforceAnalyst,
      PolicyType.DiallerAdmin,
    ],
    'View Group',
    null,
    DiallerGroupDetails,
  );

  static viewCampaign = new Page(
    '/campaigns/:campaignId',
    [
      PolicyType.TeamLeader,
      PolicyType.Manager,
      PolicyType.RealTimeAnalyst,
      PolicyType.WorkforceAnalyst,
      PolicyType.DiallerAdmin,
    ],
    'View Campaign',
    null,
    CampaignDetails,
  );

  static viewCampaignLeadList = new Page(
    '/campaigns/:campaignId/list/:listId',
    [
      PolicyType.TeamLeader,
      PolicyType.Manager,
      PolicyType.RealTimeAnalyst,
      PolicyType.WorkforceAnalyst,
      PolicyType.DiallerAdmin,
    ],
    'View List',
    null,
    LeadListDetails,
  );

  static viewImportDetails = new Page(
    '/campaigns/:campaignId/list/:listId/import/:importId',
    [PolicyType.TeamLeader, PolicyType.Manager, PolicyType.WorkforceAnalyst, PolicyType.DiallerAdmin],
    'View Import Details',
    null,
    ImportDetails,
  );

  static callbacks = new Page(
    '/callbacks',
    [PolicyType.TeamLeader, PolicyType.Manager, PolicyType.DiallerAdmin],
    'Callbacks',
    PhoneCallbackTwoToneIcon,
    Callbacks,
  );

  static asyncQueues = new Page(
    '/messaging',
    [PolicyType.TeamLeader, PolicyType.Manager, PolicyType.WorkforceAnalyst, PolicyType.DiallerAdmin],
    'Messaging Configuration',
    ContactlessTwoToneIcon,
    AsyncManagement,
  );

  static asyncQueueDetails = new Page(
    '/messaging/queue/:queue',
    [PolicyType.TeamLeader, PolicyType.Manager, PolicyType.WorkforceAnalyst, PolicyType.DiallerAdmin],
    'Queue Details',
    null,
    QueueDetails,
  );

  static system = new Page(
    '/system',
    [PolicyType.DiallerAdmin],
    'System Configuration',
    SettingsTwoToneIcon,
    SystemManagement,
  );

  // TODO: make it its own page outside of the system top level
  static agentDetails = new Page(
    '/system/agents/:username',
    [PolicyType.TeamLeader, PolicyType.Manager, PolicyType.WorkforceAnalyst, PolicyType.DiallerAdmin],
    'Agent Details',
    null,
    AgentDetails,
  );

  static accessFilterDetails = new Page(
    '/system/access-filter/:accessFilterId',
    [PolicyType.DiallerAdmin],
    'Access Filter Details',
    null,
    AccessFilterDetails,
  );

  static notFound = new Page('*', [], 'Page Not Found', null, NotFound);
}
