import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { getLeadListImportDataById, getLeadListImportDataByNextUrl } from './api';
import { LeadListImportData } from './domain';

type Options = {
  shouldFetch?: boolean;
};

const useLeadListImportData = (campaignId: number, listId: number, importId: number, options: Options = {}) => {
  const { shouldFetch = true } = options;
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [importData, setImportData] = useState<LeadListImportData[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const axiosCancelRef = useRef<CancelTokenSource>(axios.CancelToken.source());
  const observer = useRef<IntersectionObserver | undefined>(undefined);

  const getNextPage = useCallback(async () => {
    if (nextUrl !== null) {
      axiosCancelRef.current.cancel();
      try {
        setLoading(true);
        setError(false);
        axiosCancelRef.current = axios.CancelToken.source();
        const res = await getLeadListImportDataByNextUrl(nextUrl, axiosCancelRef.current);
        if (res === undefined) return;
        setImportData((prev) => [...prev, ...res.importData]);
        setHasMore(res.nextPageUrl !== null);
        setNextUrl(res.nextPageUrl);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  }, [nextUrl]);

  const reload = useCallback(async () => {
    axiosCancelRef.current.cancel();
    try {
      setLoading(true);
      setError(false);
      axiosCancelRef.current = axios.CancelToken.source();
      const res = await getLeadListImportDataById(campaignId, listId, importId);
      if (res === undefined) return;
      setImportData(res.importData);
      setHasMore(res.nextPageUrl !== null);
      setNextUrl(res.nextPageUrl);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [campaignId, listId, importId]);

  /** Ref watches for element view intersection and loads more results. Note: Should only be assigned to last element in
   * a list
   * */
  const intersectionObserverRef = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, getNextPage],
  );

  useEffect(() => {
    if (shouldFetch) {
      reload();
    }
  }, [campaignId, listId, importId, shouldFetch]);

  return { loading, error, importData, hasMore, reload, intersectionObserverRef };
};

export default useLeadListImportData;
